import gql from "graphql-tag";

export const getNewInProducts = gql`
  query getNewInProducts {
    products(where: { orderby: { field: DATE, order: DESC } }, first: 9) {
      nodes {
        id
        slug
        ... on VariableProduct {
          id
          name
          salePrice(format: FORMATTED)
          regularPrice(format: FORMATTED)
          variations(first: 100) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
        galleryImages(first: 100) {
          nodes {
            id
            slug
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;

export const getOnSaleProducts = gql`
  query getOnSaleProducts {
    products(where: { onSale: true }, first: 9) {
      nodes {
        id
        slug
        ... on VariableProduct {
          id
          name
          salePrice(format: FORMATTED)
          regularPrice(format: FORMATTED)
          variations(first: 100) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
        galleryImages(first: 100) {
          nodes {
            id
            slug
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;

export const GET_TOP_SALES_PRODUCTS = gql`
  query getTopSalesProducts {
    products(where: { orderby: { field: TOTAL_SALES } }, first: 9) {
      nodes {
        id
        slug
        ... on VariableProduct {
          id
          name
          salePrice(format: FORMATTED)
          regularPrice(format: FORMATTED)
          variations(first: 100) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
        galleryImages(first: 100) {
          nodes {
            id
            slug
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;

//TODO: update this
export const getHomeBlogPosts = gql`
  query getBestSellers {
    products(where: { onSale: true }, first: 3) {
      nodes {
        id
        slug
        ... on VariableProduct {
          id
          name
          price(format: FORMATTED)
        }
        ... on SimpleProduct {
          id
          name
          price(format: FORMATTED)
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
    }
  }
`;
