import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "../Button/Button";

import "./ConfirmationModal.scss";

interface ConfirmationModalProps {
  handleCancelOrder: () => void;
  isModalVisible: boolean;
  closeModal: () => void;
}

const ConfirmationModal = ({
  handleCancelOrder,
  isModalVisible,
  closeModal,
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isModalVisible} toggle={closeModal} className='ConfirmationModal'>
      <ModalHeader toggle={closeModal} />
      <ModalBody className='ConfirmationModal__body'>
        <div className='ConfirmationModal__body_title'>
          <h2>Je i sigurt ?</h2>
        </div>
        <div className='ConfirmationModal__body_content'>
          <p>Jeni i sigurt që dëshironi ta anuloni këtë porosi?</p>
        </div>
        <div className='ConfirmationModal__body_footer'>
          <Button color='primary' style={{ color: "#fff" }} onClick={handleCancelOrder}>
            Po
          </Button>
          <Button color='primary' style={{ color: "#fff" }} onClick={closeModal}>
            Jo
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
