import React, { useEffect, useState } from "react";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import ReactPixel from "react-facebook-pixel";

import { ProductPrices } from "../shared/ProductPrices/ProductPrices";
import { ProductPricesTemporary } from "../shared/ProductPricesTemporary/ProductPricesTemporary";

export const SingleProductContent = () => {
  const { product, variation } = useProductSingleContext();

  const [price, setPrice] = useState(() => {
    return {
      regular: product.regularPrice,
      sale: product.salePrice,
    };
  });

  useEffect(() => {
    setPrice({
      regular: variation?.regularPrice || product.regularPrice,
      sale: variation?.salePrice || product.salePrice,
    });
  }, [variation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ReactPixel.track("ViewContent", {
      content_ids: product?.databaseId,
      content_name: product?.name,
      content_type: "product single",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='SingleProductContent__header'>
        {/* <div className="top-content">
          <WishListButton id={product.databaseId} />
        </div> */}
        <h1 className='SingleProduct__content__title'>{product?.name}</h1>
        {price.sale && !variation ? (
          <ProductPricesTemporary regular={price.regular} sale={price.sale} />
        ) : (
          <ProductPrices regular={price.regular} sale={price.sale} />
        )}
      </div>

      {product?.description && (
        <div className='SingleProduct__content__description'>
          <div
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          />
        </div>
      )}
    </>
  );
};
