import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import MenuLoaders from "./MenuLoaders";
import { ShopProductCategory } from "../../../queries/shop";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useFetchMenu } from "./lib/useFetchMenu";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-downn.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import { LoadingPlaceholder } from "../../../components/shared/LoadingPlaceholder/LoadingPlaceholder";
import { useQuery } from "@apollo/react-hooks";
import * as shopQueries from "../../../queries/shop";
import { ProductTaxonomyEnum, TaxonomyOperatorEnum } from "../../../types/generalTypes";
import { useSearchParams } from "../../../lib/hooks/useSearchParams";

import "./Menu.scss";

export const Menu = () => {
  const router = useRouter();
  const { categories, loading } = useFetchMenu();
  const [opened, setOpened] = useState<string>("");

  const searchParams = useSearchParams();

  const { loading: finalCategoriesLoading, data } = useQuery<
    shopQueries.GetShopProductCategoriesResponse,
    shopQueries.GetShopProductCategoriesVariables
  >(shopQueries.getProductCategories, {
    variables: {
      customTaxonomy: [
        {
          taxonomy: ProductTaxonomyEnum.PAGJINIA,
          terms: opened,
          operator: TaxonomyOperatorEnum.AND,
        },
      ],
    },
  });

  const finalCategories = data?.productCategories?.nodes || [];

  useEffect(() => {
    setOpened("");
  }, [router.location.pathname]);

  return (
    <div onMouseLeave={() => setOpened("")} className={cn("Menu", loading && "Menu--loading")}>
      {loading ? (
        <MenuLoaders />
      ) : (
        <div className='Menu-items'>
          <div
            onClick={() => {
              router.push(`/koleksioni-ri`);
            }}
            role='button'
            className='Menu__item'
          >
            <span
              className={cn(
                "Menu__item_inner Menu__item--title",
                JSON.stringify(router.location.pathname)?.includes("koleksioni-ri") &&
                  "Menu__item--active",
              )}
            >
              Arritjet e reja
            </span>
          </div>
          {categories?.map((c: any) => {
            const hasChildrens = c.products?.nodes?.length > 0;

            const handleClick = (event: any, item: ShopProductCategory, isActive: boolean) => {
              const hasChildren = c.products?.nodes?.length > 0;

              if (hasChildren || isActive) {
                event.preventDefault();
              }

              setOpened(item.slug);

              if (!hasChildren) {
                setOpened("");
              }
            };

            const isActive = JSON.stringify(searchParams)?.includes(c.slug);

            return (
              <div
                key={c.id}
                onMouseOver={(e) => {
                  handleClick(e, c, isActive);
                }}
                role='button'
                className='Menu__item'
              >
                <div onClick={() => router.push(`/veshje-${opened}`)} className='Menu__item--title'>
                  <span className={cn("Menu__item_inner", isActive && "Menu__item--active")}>
                    {c.name}
                  </span>
                  {hasChildrens && (
                    <ArrowDown fill={isActive ? "#ef3636" : ""} width={8} height={8} />
                  )}
                </div>
                <div className='Menu-dropdown'>
                  {finalCategoriesLoading ? (
                    <LoadingPlaceholder />
                  ) : (
                    <div className='Menu-dropdown-wrapper'>
                      <ul className='Menu-dropdown__list'>
                        {finalCategories?.map((item: any) => {
                          return (
                            <li key={item?.id} className='Menu-dropdown__list-item'>
                              <>
                                <NavLink
                                  to={`/veshje-${opened}/${item?.slug}`}
                                  className='Menu-dropdown__list-item-title'
                                >
                                  {item?.name}
                                </NavLink>
                                {item?.children?.nodes?.map((childItem: any) => {
                                  const isActive = router.pathname?.includes(childItem.slug);

                                  return (
                                    <NavLink
                                      key={childItem?.id}
                                      className={cn(
                                        "Menu-dropdown__list-item-child",
                                        isActive && "Menu-dropdown__list-item-child--active",
                                      )}
                                      to={`/veshje-${opened}/${item?.slug}/${childItem?.slug}`}
                                    >
                                      {childItem?.name}
                                    </NavLink>
                                  );
                                })}
                                <NavLink
                                  className={cn("Menu-dropdown__list-item-child")}
                                  to={`/veshje-${opened}/${item?.slug}`}
                                >
                                  <ArrowRight
                                    width={14}
                                    height={14}
                                    fill='#ebebeb'
                                    style={{ marginRight: 8 }}
                                  />
                                  Te gjitha
                                </NavLink>
                              </>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div
            onClick={() => router.push(`/kategoria/zbritje`)}
            role='button'
            className='Menu__item'
          >
            <span
              className={cn(
                "Menu__item_inner Menu__item--title",
                JSON.stringify(router.location.pathname)?.includes("zbritje") &&
                  "Menu__item--active",
              )}
            >
              Zbritje
            </span>
          </div>
          <div
            onClick={() => router.push(`/kategoria/valixhe`)}
            role='button'
            className='Menu__item'
          >
            <span
              className={cn(
                "Menu__item_inner Menu__item--title",
                JSON.stringify(router.location.pathname)?.includes("valixhe") &&
                  "Menu__item--active",
              )}
            >
              Valixhe
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
