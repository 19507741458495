export const getProductStatus = (order: string) => {
  switch (order) {
    case "ON_HOLD":
      return "Në pritje";

    case "APPROVED":
      return "E aprovuar";

    case "FAILED":
      return "E dështuar";

    case "CANCELLED":
      return "E anuluar";

    case "COMPLETED":
      return "E kompletuar";

    default:
      return null;
  }
};
