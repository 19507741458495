import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { ReactComponent as ShoppingCart } from "../../assets/icons/shopping-cart.svg";
import { Quantity } from "../shared/QuantityInput/Quantity";
import { actions, errors, formValidation, messages } from "../../lib/translation/strings";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { Icon } from "../shared/Icon/Icon";
import { useWishlist } from "../../lib/hooks/useWishlist";
import { ReactComponent as HeartFill } from "../../assets/icons/heart-fill.svg";
import { Spinner } from "reactstrap";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";

import "./SingleProductButtons.scss";
import { removeHTMLEntities } from "../../lib/helpers/removeHTMLEntities";

export const SingleProductButtons = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const menuCtx = useMenuSidebar();
  const { items, addProduct } = useCartContext();
  const {
    inStock,
    product,
    variation,
    quantity,
    setQuantity,
    emptySelectedAttributes,
    selectedAttributes,
    attributes,
  } = useProductSingleContext();

  const [loading, setLoading] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(() =>
    product.type === "VARIABLE" ? 1 : product.stockQuantity,
  );

  const { addToWishlist, isProductOnWishList, getUserWishlist } = useWishlist();

  const [isFavorite, setIsFavorite] = useState(isProductOnWishList(product.databaseId));

  const handleClick = () => {
    addToWishlist(product.databaseId);
    setIsFavorite(true);
    ReactPixel.track("AddToWishlist", {
      content_ids: product?.databaseId,
      content_name: product?.name,
    });
  };

  useEffect(() => {
    if (!isProductOnWishList(product.databaseId)) {
      setIsFavorite(false);
    }
  }, [getUserWishlist]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (variation) {
      setMaxQuantity(variation.stockStatus === "IN_STOCK" ? variation.stockQuantity || 10 : 1);
    }
  }, [variation]);

  const getItemFromCart = () => {
    const cartItem =
      items?.filter((item: any) => item.product.node?.databaseId === product.databaseId) || [];

    if (cartItem.length === 0) {
      return;
    }

    if (variation) {
      return cartItem.find((item: any) => item.variation.node?.id === variation.id);
    }

    return cartItem[0] as any;
  };

  const handleAddToCart = async () => {
    const cartItem = getItemFromCart();

    if (product.type === "VARIABLE" && attributes.length === 0) {
      notification.warning(t(errors.noVariations)); // No available variations.
      return;
    }

    const ngjyra = selectedAttributes.find((s) => s.attribute === "pa_ngjyra");

    let isColor = ngjyra?.attribute === "pa_ngjyra";

    if (product.type === "VARIABLE" && !variation && isColor) {
      notification.warning(t(formValidation.selectVariation) + " " + t(messages.atSize)); //Please select a variation to add it to cart.
      return;
    }

    if (product.type === "VARIABLE" && !variation && !isColor) {
      notification.warning(t(formValidation.selectVariation) + " " + t(messages.atColor)); //Please select a variation to add it to cart.
      return;
    }

    if (product.type === "VARIABLE" && variation && !inStock) {
      notification.warning(t(errors.variationOutOfStock)); //This variation of the product is out of stock.
      return;
    }

    if (cartItem && cartItem.quantity + quantity > maxQuantity) {
      notification.warning(
        `Ju nuk mund ta shtoni atë shumë në shportë, ju tashmë keni ${cartItem?.quantity} sasi në shportën tuaj.`,
      );
      return;
    }

    if (!inStock) {
      notification.warning(t(errors.productNotInStock)); //This product is out of stock.
      return;
    }

    try {
      setLoading(true);

      await addProduct(
        product.databaseId,
        quantity,
        product.type === "VARIABLE" ? variation.databaseId : undefined,
      );
      ReactPixel.track("AddToCart", {
        content_ids: product?.databaseId,
        content_name: product?.name,
        contents: `${quantity} copë`,
        value: removeHTMLEntities(product?.salePrice)
          ? removeHTMLEntities(product?.salePrice)
          : removeHTMLEntities(product?.regularPrice),
        currency: "EUR",
      });
      menuCtx.openSidebar("cart");
    } catch (error) {
      notification.warning(error.message, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='SingleProduct__content__buttons'>
      {selectedAttributes.length > 0 && (
        <div onClick={emptySelectedAttributes} className='SingleProduct__content__buttons-recycle'>
          <Icon icon='recycle' />
          <span>Spastroje</span>
        </div>
      )}

      <div className='d-flex justify-content-between'>
        <Quantity
          hideMobile={true}
          quantity={quantity}
          maxQuantity={maxQuantity}
          disableIncrement={quantity === maxQuantity}
          onChange={(quantity: number) => setQuantity(quantity)}
        />
        <div className='SingleProduct__content__buttons--buttons'>
          <button
            id='addToCardBtn'
            className='btn--addtocart'
            onClick={handleAddToCart}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                style={{
                  color: "#fff",
                  width: "1.25rem",
                  height: "1.25rem",
                  borderWidth: ".2em",
                  margin: "auto",
                }}
              />
            ) : (
              <div className='d-flex justify-content-center align-items-center'>
                <ShoppingCart style={{ marginRight: 8 }} fill='white' width={20} height={20} />{" "}
                {t(actions.addToBag)}
              </div>
            )}
          </button>
          <button className='btn--addtocart btn--wishlist' onClick={handleClick} disabled={loading}>
            <div className='d-flex justify-content-center align-items-center'>
              {isFavorite ? (
                <HeartFill width={23} height={22} style={{ marginRight: 8 }} />
              ) : (
                <Icon style={{ marginRight: 8 }} icon='heart' />
              )}
              <span>Shto në listën e dëshirave</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
