import React, { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../lib/hooks/useRouter";
import { getFormatedDate } from "../../../lib/helpers/getFormatedDate";
import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";

import {
  getOrderById,
  GetOrderByIdResponse,
  GetOrderByIdVariables,
} from "../../../queries/ordersQueries";
import { OrdersLoader } from "./OrdersLoader";
import { OrderSingleLoader } from "./OrderSingleLoader";
import { EmptyPage } from "../../shared/EmptyMessage/EmptyPage";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { errors, fields, formInputs } from "../../../lib/translation/strings";
import { Col, Row } from "reactstrap";
import { UPDATE_ORDER_STATUS } from "../../../queries/mutations/checkoutMutation";
import { Button } from "../../shared/Button/Button";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useNotification } from "../../../lib/context/NotificationContext/NotificationContext";
import ConfirmationModal from "../../shared/ConfirmationModal/ConfirmationModal";
import { getProductStatus } from "../../../lib/helpers/getProductStatus";

import "./ProfileOrders.scss";

export const ProfileOrdersSingle = () => {
  const router: any = useRouter();
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const ntfCtx = useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const orderDetails = useQuery<GetOrderByIdResponse, GetOrderByIdVariables>(getOrderById, {
    variables: {
      id: router.match.params?.slug,
    },
  });
  const [mutation, { loading }] = useMutation(UPDATE_ORDER_STATUS);

  const orderData = orderDetails.data?.order;
  const orderList = orderData?.lineItems?.nodes ?? [];

  const cancelCurrentOrder = async () => {
    try {
      const res = await mutation({
        variables: {
          orderId: parseInt(orderData?.orderNumber!),
          status: "CANCELLED",
          userId: authCtx?.user?.userId,
        },
      });

      if (!res) {
        return;
      }

      ntfCtx.success("Porosia juaj është anuluar me sukses!", true);
    } catch (error) {
      ntfCtx.danger("Diçka shkoj keq ju lutemi provoni përsëri!", true);
    }
  };

  return (
    <>
      <HandleLoadingState
        loading={orderDetails.loading || loading}
        loadingPlaceholder={
          <div className='ProfileOrdersSingle' style={{ paddingTop: "55px" }}>
            <div className='ProfileOrdersSingle__left-side'>
              <div className='ProfileOrdersSingle__item' style={{ marginTop: "-45px" }}>
                <OrderSingleLoader />
              </div>
              <div className='ProfileOrdersSingle__item'>
                <OrdersLoader />
              </div>
              <div className='ProfileOrdersSingle__item'>
                <OrdersLoader />
              </div>
            </div>
            <div className='ProfileOrdersSingle__right-side' />
          </div>
        }
      >
        {!orderData ? (
          <EmptyPage
            title={t(fields.order)}
            buttonText={t(fields.myOrders)}
            redirectLink='my-profile/orders'
            description={t(errors.orderNotFound)}
          />
        ) : (
          <div className='ProfileOrdersSingle'>
            <span className='ProfileOrdersSingle__order-nr'>
              <strong>{t(fields.order)}: </strong>
              {orderData?.orderNumber}
            </span>
            <div className='ProfileOrdersSingle__left-side'>
              <Row>
                {orderList?.map((item: any, index: number) => {
                  return (
                    <Col xs={12} md={4} key={`${item.id}-${index}`}>
                      <div className='ProfileOrdersSingle__item'>
                        <div className='ProfileOrdersSingle__thumb'>
                          <img src={item?.product?.image?.sourceUrl} alt='' />
                        </div>
                        <div className='ProfileOrdersSingle__content'>
                          <span className='ProfileOrdersSingle__left-side__item'>
                            {item?.product?.name}
                          </span>
                          <span className='ProfileOrdersSingle__left-side__item'>
                            <strong>{removeHTMLEntities(item?.product?.regularPrice)}</strong>
                          </span>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
            <div className='ProfileOrdersSingle__right-side'>
              <Row>
                <Col xs={12} md={4}>
                  <div className='ProfileOrdersSingle__address'>
                    <h4>{t(fields.billing)}: </h4>
                    {orderData.billing.firstName && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.name)}: </strong>
                        {orderData.billing.firstName}
                      </span>
                    )}
                    {orderData.billing.lastName && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.lastName)}: </strong>
                        {orderData.billing.lastName}
                      </span>
                    )}
                    {orderData.billing.address1 && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.address)}: </strong>
                        {orderData.billing.address1}
                      </span>
                    )}
                    {orderData.billing.state && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(fields.country)}: </strong>
                        {orderData.billing.state}
                      </span>
                    )}
                    {orderData.billing.postcode && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(fields.zipCode)}: </strong>
                        {orderData.billing.postcode}
                      </span>
                    )}
                    {orderData.billing.phone && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.telephoneNumber)}: </strong>
                        {orderData.billing.phone}
                      </span>
                    )}
                    {orderData.billing.email && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.email)}: </strong>
                        {orderData.billing.email}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className='ProfileOrdersSingle__address'>
                    <h4>{t(fields.shipping)}: </h4>
                    {orderData.shipping.firstName && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.name)}: </strong>
                        {orderData.shipping.firstName}
                      </span>
                    )}
                    {orderData.shipping.lastName && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.lastName)}: </strong>
                        {orderData.shipping.lastName}
                      </span>
                    )}
                    {orderData.shipping.address1 && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.address)}: </strong>
                        {orderData.shipping.address1}
                      </span>
                    )}
                    {orderData.shipping.state && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(fields.country)}: </strong>
                        {orderData.shipping.state}
                      </span>
                    )}
                    {orderData.shipping.postcode && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(fields.zipCode)}: </strong>
                        {orderData.shipping.postcode}
                      </span>
                    )}
                    {orderData.shipping.phone && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.telephoneNumber)}: </strong>
                        {orderData.shipping.phone}
                      </span>
                    )}
                    {orderData.shipping.email && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(formInputs.email)}: </strong>
                        {orderData.shipping.email}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className='ProfileOrdersSingle__address'>
                    <h4>{t(fields.orderDetails)}: </h4>
                    {orderData.date && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(fields.orderDate)}: </strong>
                        {getFormatedDate(orderData.date)}
                      </span>
                    )}
                    {orderData.status && (
                      <span className='ProfileOrdersSingle__right-side__contact-item'>
                        <strong>{t(fields.status)}: </strong>
                        {getProductStatus(orderData.status)}
                      </span>
                    )}
                    {orderData.total && (
                      <span className='ProfileOrdersSingle__right-side__contact-item right'>
                        <strong>{t(fields.total)}: </strong>
                        {removeHTMLEntities(orderData.total)}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            {["PROCESSING", "ON_HOLD", "PENDING"].includes(orderData.status) && (
              <div>
                <Button
                  color='primary'
                  style={{ color: "#fff" }}
                  className='mt-3 btn-block'
                  onClick={() => setIsModalVisible(true)}
                >
                  Anulo porosinë
                </Button>
              </div>
            )}
          </div>
        )}
      </HandleLoadingState>
      <ConfirmationModal
        isModalVisible={isModalVisible}
        handleCancelOrder={() => {
          cancelCurrentOrder();
          setIsModalVisible(false);
        }}
        closeModal={() => setIsModalVisible(false)}
      />
    </>
  );
};
