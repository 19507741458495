import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import cs from "classnames";
import { useQuery } from "react-apollo";
import { ListOfProducts } from "../../components/ListOfProducts/ListOfProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { Layout } from "../../components/Shop/ui/Layout";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { useShopHandlers } from "../../lib/hooks/useShopHandlers";
import { ShopSidebar } from "../../components/Shop/ShopSidebar/ShopSidebar";
import { ShopContextProvider } from "../../lib/context/ShopContext/ShopContextProvider";
import { useOrderFieldsFromSlug } from "../../lib/hooks/useOrderFieldsFromSlug";
import { useInitialSearchParams } from "../../lib/hooks/useInitialSearchParams";
import { ButtonFilterMobile } from "../../components/GeneralFilters/ButtonFilterMobile/ButtonFilterMobile";
import { ShopFilterActions } from "../../components/Shop/ui/ShopFilterActions";
import * as shopQueries from "../../queries/shop";

const postsLimit = 15;
const defaultField = "DATE";
const defaultOrder = "ASC";

export interface Props {
  parentCategory?: string;
  childCategory?: string;
}

export const ShopByCategory = (props: RouteComponentProps<Props>) => {
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();
  const orderFields = useOrderFieldsFromSlug();
  const initialSearchParams = useInitialSearchParams();
  const gender = (props.match.params as any).gjinia;
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data: dataNewProducts } = useQuery(shopQueries.NEW_PRODUCTS);
  const [, /*isLoadingPrevious*/ setIsLoadingPrevious] = useState(false);
  const [priceState, setPriceState] = useState({ min: 0, max: 100 });

  const { childCategory, parentCategory } = props.match.params;

  const hasNewProducts = props.match.url.startsWith("/koleksioni-ri");

  const date = dataNewProducts?.themeOptions?.themeOptions?.newProducts?.split("/");

  const { loading, data, error, fetchMore, refetch } = useQuery<
    shopQueries.GetProductsResponse,
    shopQueries.GetProductsVariables
  >(shopQueries.getProducts, {
    fetchPolicy: "cache-first",
    variables: {
      before: "",
      after:
        initialSearchParams && initialSearchParams.page ? (initialSearchParams.page as string) : "",
      limit: postsLimit,
      category: childCategory || parentCategory || "",
      field: orderFields.field,
      order: orderFields.order,
      taxonomyFilter: shopHandlers.prepareTaxonomyFilters(searchParams),
      day: hasNewProducts ? date && parseInt(date[0]) : undefined,
      month: hasNewProducts ? date && parseInt(date[1]) : undefined,
      year: hasNewProducts ? date && parseInt(date[2]) : undefined,
    },
  });

  const handlePriceChange = (value: number[]) => {
    setPriceState({
      min: value[0],
      max: value[1],
    });
  };

  const handlePriceAfterChange = (value: number[]) => {
    refetch({
      limit: postsLimit,
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    fetchMore({
      variables: {
        limit: postsLimit,
        after: data?.products?.pageInfo?.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        // if (pageInfo.endCursor) {
        //   shopHandlers.updateSingleQueryParameter(
        //     "page",
        //     previousResult?.products?.pageInfo?.endCursor,
        //     () => {}
        //   );
        // }

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...previousResult.products.nodes, ...newNodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              endCursor: pageInfo?.endCursor,
              hasNextPage: pageInfo?.hasNextPage,
            },
          },
        };
      },
    });
  };

  const handleLoadPrevious = () => {
    setIsLoadingPrevious(true);
    fetchMore({
      variables: {
        last: postsLimit,
        first: undefined,
        limit: undefined,
        after: undefined,
        before: data?.products?.pageInfo?.startCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        setIsLoadingPrevious(false);
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...newNodes, ...previousResult.products.nodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              startCursor: pageInfo?.startCursor,
              hasPreviousPage: pageInfo?.hasPreviousPage,
            },
          },
        };
      },
    });
  };

  const handleOrderChange = (value: string) => {
    shopHandlers.updateSingleQueryParameter("order", value, (field, order) => {
      refetch({
        limit: postsLimit,
        field: field || defaultField,
        order: order || defaultOrder,
      });
    });
  };

  const handleCategoryChange = (categorySlug: string) => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(data);

    refetch({
      limit: postsLimit,
      category: categorySlug,
      taxonomyFilter: taxonomyFilters,
    });
  };

  const handleAttributeChange = (data: any) => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(data);

    refetch({
      limit: postsLimit,
      taxonomyFilter: taxonomyFilters,
    });
  };

  const getShopLinkPrefix = () => {
    let link = "";
    const { url } = props.match;
    if (url.startsWith("/veshje")) link = "veshje";
    else if (url.startsWith("/koleksioni-ri")) link = "koleksioni-ri";
    // else if (url.startsWith("/zbritje")) link = "zbritje";
    else if (url.startsWith("/kategoria")) link = "kategoria";

    if (gender) {
      link = link + "-" + gender;
    }
    return link;
  };

  const hasNextPage = !!data?.products?.pageInfo?.hasNextPage;
  const hasPreviousPage = !!data?.products?.pageInfo?.hasPreviousPage;
  const products = data?.products?.nodes;
  const noProductsAvailable = !products || Number(products.length) === 0;

  return (
    <ShopContextProvider
      data={{
        data: {
          shopType: "category",
          shopLinkPrefix: getShopLinkPrefix(),
          category: parentCategory,
          subCategory: childCategory,
          order: `${orderFields.field}-${orderFields.order}`,
        },
        handlers: {
          handleCategoryChange,
          handleAttributeChange,
          handleOrderChange,
        },
      }}
    >
      <Layout
        className={cs("ShopByCategory", noProductsAvailable && "ShopLayout--no-products")}
        sidebar={
          <ShopSidebar
            className='ShopByCategory__sidebar'
            handleChange={(value: number[]) => handlePriceChange(value)}
            handleAfterChange={(value: number[]) => handlePriceAfterChange(value)}
            priceState={priceState}
          />
        }
        content={
          <ApolloErrorGuard error={error}>
            <ButtonFilterMobile
              className={cs("ShopFilterActions__btn ShopFilterActions__filter")}
            />
            <ShopFilterActions className='ShopByCategory__filter_actions' />
            <ListOfProducts
              big
              className='ShopByCategory__products'
              loadingPlaceholderNumber={postsLimit}
              loading={loading}
              error={error?.message}
              products={products}
              isLoading={isLoadingMore}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              pageInfo={hasNextPage}
              onLoadPrevious={handleLoadPrevious}
              onLoadMore={handleLoadMore}
            />
          </ApolloErrorGuard>
        }
      />
    </ShopContextProvider>
  );
};
